const getIsNight = () => {
    const now = new Date();

    // Опции для форматирования только времени
    const options = {
        timeZone: "Europe/Moscow", // Временная зона, если нужно
        hour: "2-digit",
        hour12: false // 24-часовой формат
    };

    // Форматируем только часы
    const hr = new Intl.DateTimeFormat("ru-RU", options).format(now);

    let isNight = hr >= 23 || hr <= 8;

    return isNight;
};

export { getIsNight };
